import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { LightCard } from '../Card'
import { TYPE } from '../../theme'
import { RowBetween } from '../Row'

const LabelRow = styled.label`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text2};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledInput = styled.div`
  label {
    padding: 1rem
    border-radius: 20px;
    border: 1px solid ${({ theme }) => theme.bg2};
    background-color: ${({ theme }) => theme.bg2};
  }
  input {
    border-radius: 20px;
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
    background-color:  ${({ theme }) => theme.bg2};
  }
  input:checked + label {
    background-color:  #cecedb;
  }
`

interface SwitchSelectBoxProps {
  id: string
  onChange: (value: number) => void
  title: string
  items: { key: number, value: string, creatable: boolean }[]
}

export default function SwitchSelectBox({
  id,
  onChange,
  title,
  items
}: SwitchSelectBoxProps) {
  const onChangeValue = (event: React.FormEvent<HTMLInputElement>) => {
    onChange(+event.currentTarget.value);
  }

  return (
    <LightCard padding="0px" borderRadius={'20px'}>
      <RowBetween padding="1rem">
        <TYPE.subHeader fontWeight={500} fontSize={14}>
          {title}
        </TYPE.subHeader>
      </RowBetween>
      <RowBetween padding="0.5rem">
        <Aligner>
          {items.map(item => {
            if (item.creatable){
              let inputId = id+'-'+item.key
              return (
                <StyledInput>
                  <input type="radio" id={inputId} name={'select-'+id} value={item.key} onChange={onChangeValue}/>                  
                  <LabelRow htmlFor={inputId}>{item.value}</LabelRow>
                </StyledInput>
              )
            }else{
              return(<></>)
            }
          })}
        </Aligner>
        </RowBetween>
    </LightCard>
  )
}
