import { Currency, Pair } from '../../sdk'
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
// import { darken } from 'polished'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
// import { RowBetween } from '../Row'
// import { TYPE } from '../../theme'
import { Input as NumericalInput } from '../NumericalInput'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'

import { useActiveWeb3React } from '../../hooks'
import { useTranslation } from 'react-i18next'
// import useTheme from '../../hooks/useTheme'

const InputRow = styled.div<{ selected: boolean }>`
	align-items: flex-start;
    align-self: stretch;
    background-color: transparent;
    border: 1px none;
    display: flex;
    gap: 12px;
    height: auto;
    position: relative;
`

const CurrencySelect = styled.button<{ selected: boolean }>`
	align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: transparent;
    border-radius: 1000px;
    cursor: pointer;
    display: flex;
    gap: 4px;
    height: auto;
    mix-blend-mode: normal;
    padding: 2px 8px 2px 2px;
    position: relative;
    width: fit-content;
`

const CurrencySelectWrap = styled.div`
	align-items: center;
	background-color: var(--white);
	border: 1px solid;
	border-color: transparent;
	border-radius: 1000px;
	cursor: pointer;
	display: flex;
	gap: 4px;
	height: auto;
	mix-blend-mode: normal;
	padding: 2px 8px 2px 2px;
	position: relative;
	width: fit-content;
`


// const LabelRow = styled.div`
  // ${({ theme }) => theme.flexRowNoWrap}
  // align-items: center;
  // color: ${({ theme }) => theme.text2};
  // font-size: 0.75rem;
  // line-height: 1rem;
  // padding: 0.75rem 1rem 0 1rem;
  // span:hover {
    // cursor: pointer;
    // color: ${({ theme }) => darken(0.2, theme.text2)};
  // }
// `

// const Aligner = styled.span`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
// `

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
	background-color: transparent;
	height: 12px;
	min-width: 12px;
	mix-blend-mode: normal;
	position: relative;

  // path {
    // stroke: ${({ selected, theme }) => (selected ? theme.text2 : theme.white)};
    // stroke-width: 1.5px;
  // }
// `

const InputPanel = styled.div<{ hideInput?: boolean }>`
	align-items: flex-start;
    align-self: stretch;
    background-color: transparent;
    border: 1px none;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: auto;
    mix-blend-mode: normal;
    position: relative;
`

const StyledTextWrap = styled.div`
	align-items: flex-start;
    align-self: stretch;
    background-color: transparent;
    border: 1px none;
    display: flex;
    height: auto;
    justify-content: space-between;
    position: relative;
`

const StyledTextLeft = styled.div`
	background-color: transparent;
    letter-spacing: 0.00px;
    line-height: 14px;
    margin-top: -1px;
    mix-blend-mode: normal;
    position: relative;
    text-align: left;
    white-space: nowrap;
    width: fit-content;
	
	color: #b6b6e5;
    font-family: "Gordita-Medium", Helvetica;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`

const StyledTextRight = styled.div`
	background-color: transparent;
    letter-spacing: 0.00px;
    line-height: 12px;
    margin-top: -1px;
    mix-blend-mode: normal;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
	
	color: #71718e;
    font-family: "Roboto Mono", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
`

const Container = styled.div<{ hideInput: boolean }>`
	align-items: flex-start;
	align-self: stretch;
	background-color: #2e2e50;
	border: 1px solid;
	border-color: transparent;
	border-radius: 12px;
	box-shadow: inset 0px 1px 0px #00000040;
	display: flex;
	flex-direction: column;
	gap: 12px;
	height: auto;
	mix-blend-mode: normal;
	padding: 16px;
	position: relative;
`

const StyledTokenName = styled.span<{ active?: boolean }>`
	background-color: transparent;
	color: #21213f;
	font-family: "Gordita-Bold", Helvetica;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.00px;
	line-height: 7.2px;
	mix-blend-mode: normal;
	position: relative;
	text-align: left;
	white-space: nowrap;
	width: fit-content;
`

const StyledBalanceMax = styled.button`
  align-items: center;
    background-color: #ffffff0d;
    border: 1px solid;
    border-color: transparent;
    border-radius: 1000px;
    display: flex;
    gap: 8px;
    height: auto;
    mix-blend-mode: normal;
    padding: 9px 16px 7px;
    position: relative;
    width: fit-content;
	cursor:pointer;
	
	color: #ffffff;
    font-family: "Gordita-Bold", Helvetica;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
  customBalanceText?: string
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  customBalanceText
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  // const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id}>
	  <StyledTextWrap>
		<StyledTextLeft>{label}</StyledTextLeft>
		{account && (
			<StyledTextRight
			  onClick={onMax}
			  style={{ display: 'inline', cursor: 'pointer' }}
			>
			  {!hideBalance && !!currency && selectedCurrencyBalance
				? (customBalanceText ?? 'Balance: ') + selectedCurrencyBalance?.toSignificant(6)
				: ' -'}
			</StyledTextRight>
		  )}
	  </StyledTextWrap>
      <Container hideInput={hideInput}>
		<CurrencySelect
		selected={!!currency}
		className="open-currency-select-button"
		onClick={() => {
		  if (!disableCurrencySelect) {
			setModalOpen(true)
		  }
		}}
		>
		
			<CurrencySelectWrap>
				{pair ? (
					<DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
				) : currency ? (
					<CurrencyLogo currency={currency} size={'24px'} />
				) : null}
				
				{pair ? (
					<StyledTokenName className="pair-name-container">
						{pair?.token0.symbol}:{pair?.token1.symbol}
					</StyledTokenName>
				) : (
					<StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
						{(currency && currency.symbol && currency.symbol.length > 20
						? currency.symbol.slice(0, 4) +
						'...' +
						currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
						: currency?.symbol) || t('selectToken')}
					</StyledTokenName>
				)}
				{!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
			</CurrencySelectWrap>
		</CurrencySelect>
		<InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
			{!hideInput && (
				<>
					<NumericalInput
						className="token-amount-input"
						value={value}
						onUserInput={val => {
						onUserInput(val)
					}}
					/>
					{account && currency && showMaxButton && label !== 'To' && (
					<StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>
					)}
				</>
			)}
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
