import React from 'react'
import styled from 'styled-components'


// align-items: center;
// 
// 
// 
// 
// 
// 
// display: flex;
// flex-direction: column;
// height: auto;
// left: 506px;
// 
// position: absolute;
// top: 152px;
// width: 500px;

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  background-color: #21213ecc;
  /* padding: 1rem; */
  
  box-shadow: 0px 13px 64px #00000040;
  backdrop-filter: blur(4px) brightness(100%);
  border-radius: 24px;
  mix-blend-mode: normal;
  border-color: #32325e !important;
  border: 1px solid;
`


/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
