import React from 'react'
import styled from 'styled-components'
import Settings from '../Settings'
import { RowBetween } from '../Row'


const StyledSwapHeader = styled.div`
	align-items: center;
	align-self: stretch;
	background-color: transparent;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-color: #32325e;
	border-left-style: none;
	border-right-style: none;
	border-top-style: none;
	display: flex;
	height: auto;
	justify-content: space-between;
	padding: 20px 24px;
	position: relative;
`

const StyledText = styled.div`
	background-color: transparent;
    color: #ffffff;
    font-family: "Clash Display-Bold", Helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.24px;
    line-height: 24px;
    margin-top: -1px;
    mix-blend-mode: normal;
    position: relative;
    text-align: left;
    white-space: nowrap;
    width: fit-content;
`

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <StyledText>SWAP</StyledText>
        <Settings />
      </RowBetween>
    </StyledSwapHeader>
  )
}
